import React, { useState, useRef } from 'react';
import {
  MdEmail,
  MdVisibility,
  MdVisibilityOff,
  MdVpnKey,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SubmitHandler, FormHandles } from '@unform/core';
import { AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';

import Fade from '../../components/Shared/Animations/Fade';
import Spinner from '../../components/Shared/Spinner';
import { useAuth } from '../../contexts/auth';
import {
  Container,
  Form,
  FormGroup,
  Input,
  Password,
  SubmitButton,
  ForgotPassword,
  CreateAccount,
} from './styles';

interface FormData {
  email: string;
  password: string;
}

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email Inválido').required('Digite seu e-mail'),
  password: Yup.string().required('Digite a sua senha'),
});

const Login: React.FC = () => {
  const { signIn, unblock } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState<boolean>(false);


  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      setLoading(true);

      await LoginSchema.validate(data, {
        abortEarly: false,
      });

      await signIn(data, 'session/authenticate');

      if (unblock) {
        window.location.reload();
      } else {
        window.location.reload();
      }
    } catch (err) {
      setLoading(false);

      const validationErrors: { [key: string]: string } = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);

        return;
      }
      toast(err.response.data.message, {
        type: 'error',
      });
    }
  };

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <AnimatePresence>
          <Fade>
            <h1>Clínica Sorrir</h1>

            <FormGroup>
              <MdEmail />
              <Input name="email" placeholder="E-mail" />
            </FormGroup>

            <FormGroup>
              <MdVpnKey />
              <Input
                name="password"
                placeholder="Senha"
                type={showPassword ? 'text' : 'password'}
              />

              <Password
                tabIndex={-1}
                showPassword={showPassword}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
              </Password>
            </FormGroup>

            {/* <ForgotPassword to="/"> Esqueci minha Senha</ForgotPassword> */}

            <SubmitButton type="submit">
              {loading ? <Spinner /> : 'Entrar'}
            </SubmitButton>

            <CreateAccount>
              <Link to="/register">Criar minha conta</Link>
            </CreateAccount>
          </Fade>
        </AnimatePresence>
      </Form>
    </Container>
  );
};

export default Login;
