import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Dropdown, DropdownButton, Badge, DropdownMenu } from './styles';

interface DropdownProps {
  width?: number;
  badge?: number;
  icon: JSX.Element;
  children: JSX.Element;
}

const NavDropdown: React.FC<DropdownProps> = ({
  width,
  badge,
  icon,
  children,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <Dropdown>
        <DropdownButton onClick={() => setVisible(!visible)}>
          {icon}
          {badge ? <Badge>{badge}</Badge> : null}
        </DropdownButton>

        <DropdownMenu width={width} dropdownVisible={visible}>
          {children}
        </DropdownMenu>
      </Dropdown>
    </OutsideClickHandler>
  );
};

export default NavDropdown;
