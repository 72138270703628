import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';

import { api } from '../services/api';

interface User {
  name: string;
}

interface AuthContextData {
  user: User | null;
  signed: boolean;
  loading: boolean;
  is_admin: boolean;
  unblock: boolean;
  signIn: (data: any, APIRoute: string) => Promise<void>;
  signOut: () => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const [is_admin, setIsAdmin] = useState<boolean>(false);
  const [unblock, setunblock] = useState<boolean>(false);
  useEffect(() => {
    async function loadStorageData() {
      const storagedUser = localStorage.getItem('@SorrirAuth:user');
      const storagedToken = localStorage.getItem('@SorrirAuth:token');
      const storagedAdmin = localStorage.getItem('@SorrirAuth:is_admin');
      const storagedBlock = localStorage.getItem('@SorrirAuth:unblock');
      await new Promise(resolve => setTimeout(resolve, 2000));

      if (storagedUser && storagedToken && storagedAdmin && storagedBlock) {
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`;

        setUser(JSON.parse(storagedUser));
        setIsAdmin(JSON.parse(storagedAdmin));
        setunblock(JSON.parse(storagedBlock));
      }

      setLoading(false);
    }

    loadStorageData();
  }, []);

  // const resposta = api.post('session/authenticate');
  // console.log(resposta);
  const signIn = useCallback(async (data: any, APIRoute: string) => {
    const response = await api.post(APIRoute, data);

    setUser(response.data.user);

    setIsAdmin(response.data.user.is_admin);
    setunblock(response.data.unblock);

    api.defaults.headers.Authorization = `Bearer ${response.data.token}`;

    localStorage.setItem(
      '@SorrirAuth:user',
      JSON.stringify(response.data.user),
    );
    localStorage.setItem(
      '@SorrirAuth:is_admin',
      JSON.stringify(response.data.user.is_admin),
    );
    localStorage.setItem(
      '@SorrirAuth:unblock',
      JSON.stringify(response.data.user.unblock),
    );
    localStorage.setItem('@SorrirAuth:token', response.data.token);
    localStorage.setItem(
      '@SorrirAuth:refreshToken',
      response.data.refreshToken,
    );
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    localStorage.clear();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        loading,
        user,
        is_admin,
        unblock,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
