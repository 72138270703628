import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import '@material/react-layout-grid/dist/layout-grid.css';

export default createGlobalStyle`
  :root {
    font-size: 60%;

    --primary: #e69d04;
    --secondary: #044de6;
    --error: #dc3545;
    --background: rgba(255, 255, 255, 0.2);
    --background-content: rgba(203, 203, 210, 0.15);
    --sidebar-width: 26rem;
    --navbar-height: 5.7rem;
  }

  * {
    margin: 0;
    padding: 0;
    outline: none;
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    height: 100vh;
    font-family: 'Roboto', sans-serif;
    background: var(--color-background);
  }

  .Toastify__toast-container {
    font-size: 1.4rem;
  }

  @media (min-width: 700px) {
    :root {
      font-size: 62.5%;
    }
  }
`;
