import React, { createContext, useContext, useState } from 'react';

interface SidebarContextData {
  sidebarVisible: boolean;
  setSidebarVisible: (active: boolean) => void;
}

const SidebarContext = createContext<SidebarContextData>(
  {} as SidebarContextData,
);

const SidebarProvider: React.FC = ({ children }) => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  return (
    <SidebarContext.Provider value={{ sidebarVisible, setSidebarVisible }}>
      {children}
    </SidebarContext.Provider>
  );
};

function useSidebar() {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error("useSidebar must be used within SidebarProvider");
  }

  return context;
}

export { SidebarProvider, useSidebar }
