import React from 'react';

import { useSidebar } from '../../contexts/sidebar';
import MainRoutes from '../../routes/main.routes';
import { Container } from './styles';

const MainContent: React.FC = () => {
  const { sidebarVisible } = useSidebar();

  return (
    <Container sidebarVisible={sidebarVisible}>
      <MainRoutes />
    </Container>
  );
};

export default MainContent;
