import styled from 'styled-components';

export const Container = styled.div`
  label {
    color: rgb(154, 154, 154);
    font-size: 1.1rem;
    font-weight: 400;
    display: block;
    margin-bottom: 0.5rem;
    text-transform: uppercase;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input {
    width: 100%;
    display: block;
    color: #495057;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5;
    background-color: #fff;
    padding: 0.8rem 1.2rem;
    border-radius: 0.5rem;
    border: 1px solid #ced4da;
    background-clip: padding-box;
    box-shadow: 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    ::placeholder {
      font-size: 1.5rem;
      font-weight: 500;
      color: #b0b0b0;
    }

    :focus {
      color: #495057;
      background-color: #fff;
      border-color: #fcce6d;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(230, 157, 4, 0.25);
    }

    :disabled {
      color: #444;
      cursor: not-allowed;
      background-color: #f5f5f5;
    }

    &.is-invalid {
      border-color: var(--error);
    }
  }

  .invalid-feedback {
    width: 100%;
    color: var(--error);
    font-size: 1.4rem;
    margin-top: 0.5rem;
  }
`;
