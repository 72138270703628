import styled from 'styled-components';

interface ContainerProps {
  sidebarVisible: boolean;
}

export const Container = styled.nav<ContainerProps>`
  width: var(--sidebar-width);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  overflow: auto;
  background-color: var(--primary);
  transition: all 0.5s ease;
  transform: ${props =>
    props.sidebarVisible
      ? 'translateX(0)'
      : 'translateX(calc(-1 * var(--sidebar-width)))'};

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: #e69d04;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

export const NavBrand = styled.div`
  width: 100%;
  height: 5.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.6rem;
  margin-bottom: 2.5rem;
  font-family: 'Roboto', sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);

  span {
    color: white;
    font-size: 1.8rem;
    cursor: pointer;
    font-weight: 600;
    line-height: 3rem;
    padding: 0.5rem 0;
    margin: 0 1.6rem 0 0;
    text-transform: uppercase;
  }
`;
