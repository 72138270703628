import React from 'react';

import { motion } from 'framer-motion';

interface Props {
  children: React.ReactNode;
}

const Fade: React.FC<Props> = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: 0, y: 30 }}
      animate={{ opacity: 1, x: 0, y: 0 }}
      exit={{ opacity: 0, x: 0, y: 30 }}
    >
      {children}
    </motion.div>
  );
};

export default Fade;
