import { Form as Unform } from '@unform/web';
import styled from 'styled-components';

import Button from '../../components/Shared/Button';
import TextInput from '../../components/Shared/Inputs/TextInput';

interface PasswordProps {
  showPassword: boolean;
}

export const Container = styled.div`
  height: 100vh;
  display: flex;
  padding: 30px;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
`;

export const Form = styled(Unform)`
  width: 100%;
  max-width: 360px;

  h1 {
    color: white;
    font-size: 3.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const Input = styled(TextInput)`
  height: 50px;
  padding: 0 2.65em !important;
`;

export const FormGroup = styled.div`
  position: relative;
  margin-bottom: 1rem;

  > svg {
    position: absolute;
    top: 26px;
    left: 16px;
    font-size: 1.9rem;
    fill: var(--secondary);
    transform: translateY(-50%);
  }
`;

export const Password = styled.a<PasswordProps>`
  position: absolute;
  top: 26px;
  right: 16px;
  font-size: 1.7rem;
  color: var(--secondary);
  transform: translateY(-50%);
  cursor: pointer;
  opacity: ${props => props.showPassword && '0.7'};

  svg {
    font-size: 1.9rem;
    fill: var(--secondary);
  }
`;

export const RegisterButton = styled(Button)`
  width: 100%;
  height: 50px;
  margin-top: 2rem;
  font-size: 1.6rem;
  font-weight: bold;
  text-transform: uppercase;
  background: var(--secondary);
`;

export const BackButton = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: white;
    font-size: 1.5rem;
    font-weight: 500;
    text-decoration: none;
    transition: opacity 0.2s ease 0s;

    :hover {
      opacity: 0.7;
    }
  }

  svg {
    color: white;
    font-size: 1.8rem;
    margin-right: 1rem;
  }
`;
