/* eslint-disable react/no-children-prop */
import React, { Suspense, lazy } from 'react';
import { Route, RouteProps, Switch, useLocation } from 'react-router-dom';

import { AnimatePresence, AnimatePresenceProps } from 'framer-motion';

import Loading from '../components/Shared/Loading';

const Fade = lazy(() => import('../components/Shared/Animations/Fade'));
const NotFound = lazy(() => import('../pages/NotFound'));

const AddEmployee = lazy(() => import('../pages/AddEmployee'));
const Employees = lazy(() => import('../pages/Lists/Employees'));

const AddPatient = lazy(() => import('../pages/AddPatient'));
const Patients = lazy(() => import('../pages/Lists/Patients'));
const EditPatient = lazy(() => import('../pages/EditPatient'));

const AddAppointment = lazy(() => import('../pages/AddAppointment'));
const Appointments = lazy(() => import('../pages/Lists/Appointments'));

const AddFinancialMovement = lazy(
  () => import('../pages/AddFinancialMovement'),
);

const FinancialReport = lazy(() => import('../pages/Reports/FinancialReport'));
const EmployeeReport = lazy(() => import('../pages/Reports/EmployeeReport'));
const MarketingReport = lazy(() => import('../pages/Reports/MarketingReport'));
const DizimoReport = lazy(() => import('../pages/Reports/DizimoReport'));
const HealthEnsure = lazy(() => import('../pages/Reports/HealthEnsure'));

const AnimatedRoutes: React.FC<AnimatePresenceProps> = ({
  children,
  ...rest
}) => {
  const location = useLocation();

  return (
    <AnimatePresence {...rest}>
      <Switch key={location.pathname} location={location}>
        {children}
      </Switch>
    </AnimatePresence>
  );
};

const RouterTransition: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <Route {...rest}>
      <Fade>{children}</Fade>
    </Route>
  );
};

const MainRoutes: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <AnimatedRoutes exitBeforeEnter initial={false}>
        <RouterTransition exact path="/" children={<AddEmployee />} />
        <RouterTransition
          exact
          path="/employee/add"
          children={<AddEmployee />}
        />
        <RouterTransition
          exact
          path="/employee/edit/:id"
          children={<AddEmployee />}
        />
        <RouterTransition
          exact
          path="/employee/list"
          children={<Employees />}
        />
        <RouterTransition exact path="/patient/add" children={<AddPatient />} />
        <RouterTransition
          exact
          path="/patient/edit/:id"
          children={<AddPatient />}
        />
        <RouterTransition exact path="/patient/list" children={<Patients />} />
        <RouterTransition
          exact
          path="/appointment/add"
          children={<AddAppointment />}
        />
        <RouterTransition
          exact
          path="/appointment/edit/:id"
          children={<AddAppointment />}
        />
        <RouterTransition
          exact
          path="/appointment/list"
          children={<Appointments />}
        />
        <RouterTransition
          exact
          path="/financial/add"
          children={<AddFinancialMovement />}
        />
        <RouterTransition
          exact
          path="/financial/edit/:id"
          children={<AddFinancialMovement />}
        />
        <RouterTransition
          exact
          path="/report/financial"
          children={<FinancialReport />}
        />
        <RouterTransition
          exact
          path="/report/employee"
          children={<EmployeeReport />}
        />
        <RouterTransition
          exact
          path="/report/marketing"
          children={<MarketingReport />}
        />
        <RouterTransition
          exact
          path="/report/dizimo"
          children={<DizimoReport />}
        />
        <RouterTransition
          exact
          path="/report/planos"
          children={<HealthEnsure />}
        />
        <RouterTransition exact path="*" children={<NotFound />} />
      </AnimatedRoutes>
    </Suspense>
  );
};

export default MainRoutes;
