import React, { useCallback, useEffect, useRef } from 'react';
import { MdMenu, MdMoreVert, MdExitToApp, MdCake } from 'react-icons/md';
import Lottie from 'react-lottie';
import happy_birthday from '../../assets/happy-birthday.json';
import { useAuth } from '../../contexts/auth';
import { useSidebar } from '../../contexts/sidebar';
import { useFetch } from '../../hooks/useFetch';
import NavDropdown from '../Shared/Dropdown';
import Modal, { ModalHandles } from '../Shared/Modal';
import ProfileInfo from '../Shared/ProfileInfo';
import {
  Container,
  Nav,
  DropdownItem,
  BirthdayContent,
  Message,
  BirthTitle,
  Birthdays,
} from './styles';

interface Birthdays {
  id: string;
  name: string;
  email: string;
  phone: string;
  profile: {
    avatar: string;
  };
}

const Navbar: React.FC = () => {
  const { signOut, user } = useAuth();
  const modalRef = useRef<ModalHandles>(null);
  const { sidebarVisible, setSidebarVisible } = useSidebar();
  const { data: birthdays } = useFetch<Birthdays[]>('/birthday');

  useCallback(() => {
    const birthdayModal = localStorage.getItem(
      '@SorrirModal:birthday',
    ) as string;

    if (!!birthdays?.length && JSON.parse(birthdayModal) === null) {
      modalRef.current?.openModal();
      localStorage.setItem('@SorrirModal:birthday', 'true');
    }
  }, [birthdays])
  // useEffect(() => {

  // }, [birthdays]);


  return (
    <Container showSidebar={sidebarVisible}>
      <Nav>
        <MdMenu onClick={() => setSidebarVisible(!sidebarVisible)} />
        <h2>Bem vindo, {user?.name}</h2>
      </Nav>
      <Nav>
        <NavDropdown width={310} icon={<MdCake />} badge={birthdays?.length}>
          <BirthdayContent>
            {birthdays?.length ? (
              birthdays?.map(birthday => (
                <DropdownItem key={birthday.id}>
                  <ProfileInfo
                    avatar={birthday.profile.avatar}
                    name={birthday.name}
                    phone={birthday.phone}
                    email={birthday.email}
                  />
                </DropdownItem>
              ))
            ) : (
              <Message>
                <span>Sem aniversariantes hoje</span>
              </Message>
            )}
          </BirthdayContent>
        </NavDropdown>
        <NavDropdown icon={<MdMoreVert />}>
          <DropdownItem onClick={() => { signOut(); window.location.reload() }}>
            Sair
            <MdExitToApp />
          </DropdownItem>
        </NavDropdown>
      </Nav>
      <Modal ref={modalRef}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: happy_birthday,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          width={200}
          height={200}
          isPaused={false}
          isStopped={false}
          isClickToPauseDisabled
        />

        <BirthTitle>Aniversariante(s) do Dia!</BirthTitle>

        {birthdays?.map(birthday => (
          <Birthdays key={birthday.id}>{birthday.name}</Birthdays>
        ))}
      </Modal>
    </Container >
  );
};

export default Navbar;
