import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Container } from './styles';

interface Props {
  name: string;
  label?: string;
}
type InputProps = JSX.IntrinsicElements['input'] & Props;

const TextInput: React.FC<InputProps> = ({ name, label, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <input
        id={fieldName}
        ref={inputRef}
        onFocus={clearError}
        className={error ? 'is-invalid' : ''}
        defaultValue={defaultValue}
        {...rest}
      />
      {error && <p className="invalid-feedback">{error}</p>}
    </Container>
  );
};
export default TextInput;
