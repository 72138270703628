import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';

import {
  Container,
  Dialog,
  Content,
  Header,
  Title,
  Footer,
  CloseButton,
} from './styles';

export interface ModalHandles {
  openModal: () => void;
}

interface ModalProps {
  title?: string;
  icon?: JSX.Element;
  children: React.ReactNode;
}

const Modal: React.ForwardRefRenderFunction<ModalHandles, ModalProps> = (
  { children, icon, title },
  ref,
) => {
  const [visible, setVisible] = useState(false);

  const openModal = useCallback(() => {
    setVisible(true);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      openModal,
    };
  });

  return createPortal(
    <Container visible={visible}>
      <Dialog>
        <Content>
          <Header>
            <Title>
              {icon}
              {title}
            </Title>
          </Header>

          {children}

          <Footer>
            <CloseButton onClick={() => setVisible(false)}>Fechar</CloseButton>
          </Footer>
        </Content>
      </Dialog>
    </Container>,
    document.getElementById('modal') as Element,
  );
};

export default forwardRef(Modal);
