import axios from 'axios';

const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_API_LOCALHOST
      : process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.data.message?.name === 'TokenExpiredError') {
      const refreshToken = localStorage.getItem('@SorrirAuth:refreshToken');

      api
        .post('session/refresh-token', { token: refreshToken })
        .then(res => {


          api.defaults.headers.Authorization = `Bearer ${res.data.token}`;
          localStorage.setItem('@SorrirAuth:token', res.data.token);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }

    return Promise.reject(error);
  },
);

export { api, axios };
