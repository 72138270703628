/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  RouteProps,
} from 'react-router-dom';

import Loading from '../components/Shared/Loading';
import { useAuth } from '../contexts/auth';
import Layout from '../pages/Layout';
import Login from '../pages/Login';
import Register from '../pages/Register';

interface PrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  isAuthenticated,
  children,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const Routes: React.FC = () => {
  const { signed, loading, unblock } = useAuth();

  if (loading) return <Loading />;

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          {signed && unblock ? <Redirect to="/" /> : <Login />}
        </Route>

        <Route path="/register">
          {signed && unblock ? <Redirect to="/" /> : <Register />}
        </Route>
        <PrivateRoute isAuthenticated={signed} path="/" children={<Layout />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
