import styled from 'styled-components';

export const Container = styled.div`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: white;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;

  @keyframes spinner-grow {
    0% {
      transform: scale(0);
    }
    50% {
      opacity: 1;
    }
  }
`;
