import React from 'react';
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from './contexts/auth';
import Routes from './routes/routes';
import GlobalStyles from './styles/GlobalStyles';

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Routes />
      <ToastContainer />
      <GlobalStyles />
    </AuthProvider>
  );
};

export default App;
