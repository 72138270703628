import styled from 'styled-components';

export const Profile = styled.div`
  display: flex;
  align-items: center;
`;

export const Avatar = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 1.5rem;
`;

export const Info = styled.div`
  h4 {
    overflow: hidden;
    font-weight: 500;
    max-width: 200px;
    font-size: 1.5rem;
    text-overflow: ellipsis;
  }

  p {
    color: #9a9a9a;
    overflow: hidden;
    max-width: 200px;
    font-size: 1.4rem;
    text-overflow: ellipsis;
  }
`;
