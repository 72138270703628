import styled from 'styled-components';

interface Props {
  sidebarVisible: boolean;
}

export const Container = styled.div<Props>`
  z-index: 1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  cursor: pointer;
  background-color: black;
  transition: all 0.7s ease;
  opacity: ${props => (props.sidebarVisible ? 0.5 : 0)};
  visibility: ${props => (props.sidebarVisible ? 'visible' : 'hidden')};

  @media (min-width: 1300px) {
    display: ${props => props.sidebarVisible && 'none'};
    visibility: ${props => props.sidebarVisible && 'hidden'};
  }
`;
