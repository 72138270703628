import React from 'react';

import { useSidebar } from '../../contexts/sidebar';
import { Container } from './styles';

const Overlay: React.FC = () => {
  const { sidebarVisible, setSidebarVisible } = useSidebar();

  return (
    <Container
      sidebarVisible={sidebarVisible}
      onClick={() => setSidebarVisible(false)}
    />
  );
};

export default Overlay;
