import styled from 'styled-components';

interface Props {
  showSidebar: boolean;
}

export const Container = styled.div<Props>`
  width: 100%;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1.6rem;
  background-color: var(--white);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease;

  svg {
    font-size: 24px;
    color: var(--primary);
    cursor: pointer;

    &:hover {
      color: #9b6a03;
    }
  }

  @media (min-width: 1308px) {
    float: right;
    width: ${props => props.showSidebar && 'calc(100% - var(--sidebar-width))'};
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;

  h2 {
    color: #9a9a9a;
    font-weight: 400;
    margin-left: 1rem;
  }
`;

export const DropdownItem = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #333;
  cursor: pointer;
  font-size: 1.6rem;
  padding: 1.2rem 2.4rem;
  transition: background-color 0.2s ease 0s;

  :not(:last-child) {
    border-bottom: 1px solid #e9ecef;
  }
`;

export const BirthdayContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-size: 1.4rem;
  color: rgb(168, 168, 179);
`;

export const Message = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
`;

export const BirthTitle = styled.h1`
  color: #fa8c15;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
`;

export const Birthdays = styled.h5`
  color: #fbb522;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
`;
