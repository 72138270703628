import styled from 'styled-components';

interface DropdownMenuProps {
  width?: number;
  dropdownVisible: boolean;
}

export const Dropdown = styled.div`
  position: relative;
  padding: 0.5rem 1rem;
`;

export const DropdownButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background: transparent;
`;

export const Badge = styled.div`
  line-height: 12px;
  padding: 2px 5px;
  position: absolute;
  right: -10px;
  top: -5px;
  border-radius: 50%;
  color: #ffffff;
  font-weight: 500;
  background-color: var(--error);
`;

export const DropdownMenu = styled.div<DropdownMenuProps>`
  position: absolute;
  right: 0;
  z-index: 2;
  top: calc(100% + 25px);
  width: ${({ width }) => (width ? `${width}px` : '150px')};
  margin: 0.2rem 0 0;
  font-size: 1.4rem;
  border-radius: 10px;
  background-color: #fff;
  transition: all 0.15s linear;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  opacity: ${props => (props.dropdownVisible ? 1 : 0)};
  display: ${props => (props.dropdownVisible ? 'initial' : 'none')};
  visibility: ${props => (props.dropdownVisible ? 'visible' : 'hidden')};

  &:before {
    content: '';
    border-bottom: 11px solid rgba(0, 0, 0, 0.2);
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    display: inline-block;
    position: absolute;
    left: auto;
    right: 10px;
    top: -12px;
  }

  &:after {
    content: '';
    border-bottom: 11px solid #ffffff;
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    display: inline-block;
    position: absolute;
    left: auto;
    right: 10px;
    top: -11px;
  }
`;
