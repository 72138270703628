import React, { useEffect } from 'react';
import {
  MdFormatListBulleted,
  MdFace,
  MdAdd,
  MdPerson,
  MdAssignment,
  MdAttachMoney,
  MdMonetizationOn,
  MdAssessment,
  MdAddAPhoto,
  MdLibraryBooks,
  MdRecentActors,
  MdDescription,
} from 'react-icons/md';

import { useAuth } from '../../contexts/auth';
import { useSidebar } from '../../contexts/sidebar';
import useWindowSize from '../../hooks/useWindowSize';
import Accordion from '../Accordion';
import { Container, NavBrand } from './styles';

const sidebarRoutes = [
  {
    name: 'Funcionários',
    icon: MdFace,
    routes: [
      {
        icon: MdAdd,
        name: 'Adicionar Funcionário',
        path: '/employee/add',
      },
      {
        icon: MdFormatListBulleted,
        name: 'Lista de Funcionários',
        path: '/employee/list',
      },
    ],
  },
  {
    name: 'Pacientes',
    icon: MdPerson,
    routes: [
      {
        icon: MdAdd,
        name: 'Adicionar Paciente',
        path: '/patient/add',
      },
      {
        icon: MdFormatListBulleted,
        name: 'Lista de Pacientes',
        path: '/patient/list',
      },
    ],
  },
  {
    name: 'Atendimentos',
    icon: MdAssignment,
    routes: [
      {
        icon: MdAdd,
        name: 'Agendar Atendimento',
        path: '/appointment/add',
      },
      {
        icon: MdFormatListBulleted,
        name: 'Lista de Atendimentos',
        path: '/appointment/list',
      },
    ],
  },
];

const sideBarAdminRoutes = [
  {
    name: 'Movimentações',
    icon: MdAttachMoney,
    routes: [
      {
        icon: MdAdd,
        name: 'Movimentação',
        path: '/financial/add',
      },
    ],
  },

  {
    name: 'Relatórios',
    icon: MdAssessment,
    routes: [
      {
        icon: MdMonetizationOn,
        name: 'Financeiro',
        path: '/report/financial',
      },
      {
        icon: MdFace,
        name: 'Funcionário',
        path: '/report/employee',
      },
      {
        icon: MdAddAPhoto,
        name: 'Acões de Marketing',
        path: '/report/marketing',
      },
      {
        icon: MdDescription,
        name: 'Plano de Clientes',
        path: '/report/planos',
      },
      {
        icon: MdLibraryBooks,
        name: 'Dizimo',
        path: '/report/dizimo',
      },
    ],
  },
];
const SideBar: React.FC = () => {
  const { width } = useWindowSize();
  const { sidebarVisible, setSidebarVisible } = useSidebar();
  const { is_admin, unblock } = useAuth();

  useEffect(() => {
    function handleResizeWindow() {
      if (width >= 1300) {
        setSidebarVisible(true);
      } else {
        setSidebarVisible(false);
      }
    }

    handleResizeWindow();
  }, [setSidebarVisible, width]);

  return (
    <Container sidebarVisible={sidebarVisible}>
      <NavBrand>
        <span>Clínica Sorrir</span>
      </NavBrand>
      {sidebarRoutes.map(route => (
        <Accordion key={route.name} route={route} />
      ))}
      {is_admin &&
        sideBarAdminRoutes.map(route => (
          <Accordion key={route.name} route={route} />
        ))}
    </Container>
  );
};

export default SideBar;
