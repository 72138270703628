import { NavLink as Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

interface ULProps {
  height: number | undefined;
}

const NavItemCSS = css`
  display: flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  color: rgb(255, 255, 255);
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 3rem;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 200ms ease;

  svg {
    font-size: 2.4rem;
    min-width: 2rem;
    margin-right: 0.7rem;
    color: var(--secondary);
  }

  :hover {
    color: #fff;
    background: hsla(0, 0%, 100%, 0.1);
  }
`;

export const NavItem = styled.div`
  ${NavItemCSS}
  cursor: pointer;
`;

export const NavLink = styled(Link)`
  ${NavItemCSS}
  font-size: 1.3rem;
  padding-left: 3.5rem;
`;

export const MenuItems = styled.ul<ULProps>`
  width: 100%;
  overflow: hidden;
  transition: height 0.35s ease-in-out;
  height: ${props => `${props.height}px`};

  .active {
    color: #fff;
    background: hsla(0, 0%, 100%, 0.23);
  }
`;
