import React, { useState, useRef } from 'react';
import {
  MdEmail,
  MdVisibility,
  MdVisibilityOff,
  MdVpnKey,
  MdPerson,
  MdArrowBack,
} from 'react-icons/md';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { SubmitHandler, FormHandles } from '@unform/core';
import { AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';
import swal from 'sweetalert';

import Fade from '../../components/Shared/Animations/Fade';
import Spinner from '../../components/Shared/Spinner';
import { useAuth } from '../../contexts/auth';
import {
  Container,
  Form,
  FormGroup,
  Input,
  Password,
  RegisterButton,
  BackButton,
} from './styles';

interface FormData {
  name: string;
  email: string;
  password: string;
}

const RegisterSchema = Yup.object().shape({
  name: Yup.string().required('Digite seu nome'),
  email: Yup.string().required('Digite seu e-mail'),
  password: Yup.string().required('Digite a sua senha'),
});

const Register: React.FC = () => {
  const { signIn, unblock } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit: SubmitHandler<FormData> = async data => {
    try {
      setLoading(true);

      await RegisterSchema.validate(data, {
        abortEarly: false,
      });

      await signIn(data, 'session/register');
      swal({
        title: "Cadastrado com sucesso!",
        text: "Por favor, espere a aprovação do administrador para efetuar o login! \nA página será atualizada em instantes.",
        icon: "success",
      });
      setTimeout(() => {
        window.location.reload()
      }, 5000);

    } catch (err) {
      setLoading(false);

      const validationErrors: { [key: string]: string } = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      }

      toast(err.response.data.message, {
        type: 'error',
      });
    }
  };

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <AnimatePresence>
          <Fade>
            <h1>Crie sua conta</h1>

            <FormGroup>
              <MdPerson />
              <Input name="name" placeholder="Seu nome" />
            </FormGroup>

            <FormGroup>
              <MdEmail />
              <Input name="email" placeholder="Seu e-mail" />
            </FormGroup>

            <FormGroup>
              <MdVpnKey />
              <Input
                name="password"
                placeholder="Sua senha"
                type={showPassword ? 'text' : 'password'}
              />

              <Password
                tabIndex={-1}
                showPassword={showPassword}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <MdVisibilityOff /> : <MdVisibility />}
              </Password>
            </FormGroup>

            <RegisterButton type="submit">
              {loading ? <Spinner /> : 'Cadastrar'}
            </RegisterButton>

            <BackButton>
              <MdArrowBack />
              <Link to="/login">Voltar para Login</Link>
            </BackButton>
          </Fade>
        </AnimatePresence>
      </Form>
    </Container>
  );
};

export default Register;
