import styled from 'styled-components';

interface Props {
  sidebarVisible: boolean;
}

export const Container = styled.div<Props>`
  width: 100%;
  padding: 3rem;
  background: var(--background-content);
  transition: all 0.5s ease;
  min-height: calc(100vh - var(--navbar-height));

  @media (min-width: 1308px) {
    float: right;
    width: ${props =>
      props.sidebarVisible && 'calc(100% - var(--sidebar-width))'};
  }
`;
