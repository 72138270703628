import styled from 'styled-components';

import Button from '../Button';

interface ModalProps {
  visible: boolean;
}

export const Container = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

export const Dialog = styled.div`
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
  transition: transform 0.3s ease-out;

  @media (min-width: 576px) {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  @media (min-width: 576px) {
    min-height: calc(100% - 3.5rem);
  }

  @media (min-width: 992px) {
    max-width: 800px;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  outline: 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.6rem;
`;

export const Title = styled.h2`
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 2.2rem;
  font-weight: 300;
  color: #212529;

  svg {
    flex-shrink: 0;
    color: var(--primary);
    margin-right: 0.5rem;
  }
`;

export const Body = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 2rem;
`;

export const Footer = styled.div`
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
`;

export const CloseButton = styled(Button)`
  background: var(--primary);
`;
