import useSWR from 'swr';

import { api } from '../services/api';

export function useFetch<Data = any>(apiURL: string) {
  const { data, error, mutate } = useSWR<Data>(
    apiURL,
    async url => {
      const response = await api.get<Data>(url);

      return response.data;
    },
    {
      errorRetryCount: 2,
      revalidateOnReconnect: true,
    },
  );

  return { data, error, mutate };
}
