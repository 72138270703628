import React, { useState, useRef, useEffect } from 'react';
import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';

import { useSidebar } from '../../contexts/sidebar';
import useWindowSize from '../../hooks/useWindowSize';
import { NavItem, MenuItems, NavLink } from './styles';

interface AccordionProps {
  route: {
    name?: string;
    icon: IconType;
    routes: {
      path: string;
      name: string;
      icon: IconType;
    }[];
  };
}
const Accordion: React.FC<AccordionProps> = ({ route }) => {
  const location = useLocation();
  const { width } = useWindowSize();
  const { setSidebarVisible } = useSidebar();
  const contentRef = useRef<HTMLUListElement>(null);
  const [accordionOpen, setAccordionOpen] = useState(false);

  useEffect(() => {
    const pathname = route.routes.find(link => link.path === location.pathname);

    if (pathname !== undefined) {
      setAccordionOpen(true);
    }
  }, [route, location]);

  return (
    <>
      <NavItem onClick={() => setAccordionOpen(!accordionOpen)}>
        <route.icon />
        {route.name}
      </NavItem>

      <MenuItems
        ref={contentRef}
        height={accordionOpen ? contentRef.current?.scrollHeight : 0}
      >
        {route.routes.map(link => (
          <NavLink
            exact
            key={link.name}
            to={link.path}
            activeClassName="active"
            onClick={() => setSidebarVisible(width >= 1300)}
          >
            <link.icon />
            {link.name}
          </NavLink>
        ))}
      </MenuItems>
    </>
  );
};

export default Accordion;
