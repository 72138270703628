import styled from 'styled-components';

export default styled.button`
  display: block;
  margin-left: auto;
  outline: none;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  padding: 0.8rem 1.6rem;
  border-radius: 0.3rem;
  line-height: 1.5;
  color: white;
  font-family: 'Roboto', sans-serif;
  border: 1px solid transparent;
  transition: opacity 0.7s ease;

  :hover {
    opacity: 0.8;
  }

  :disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }

  @media (max-width: 467px) {
    width: 100%;
  }
`;
