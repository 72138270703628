import React from 'react';

import { Profile, Avatar, Info } from './styles';

interface ProfileInfoProps {
  avatar: string;
  name: string;
  phone?: string;
  email?: string;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({
  avatar,
  name,
  phone,
  email,
}) => {
  return (
    <Profile>
      <Avatar src={avatar} alt="Avatar" />

      <Info>
        <h4>{name}</h4>
        <p>{phone}</p>
        <p>{email}</p>
      </Info>
    </Profile>
  );
};

export default ProfileInfo;
